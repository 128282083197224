<template>
  <div
    style="
      overflow-x: scroll;
      overflow-y: scroll;
      margin-left: auto;

      margin: auto;
      max-height: 90vh;
    "
  >
    <div>
      <button
        style="margin-top: 10px; margin-bottom: 10px"
        @click="init()"
        class="btn btn-success"
        id="btnShowTable"
        title="Отчет загружается крайне долго (если делать это для всей школы на большой диапазон). Старайтесь использовать его для конкретного класса"
      >
        Сводный отчет<br />
        по диапазону дат (Дети)
      </button>
      <div v-if="progress === 100">
        <div
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
          "
        >
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="eatShow"
              @change="showEat()"
              checked
            />
            <label class="form-check-label" for="eatShow">
              Отметки Питания
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked
              @change="showCat()"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Категории в питании
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              @change="showCatNotEating = !showCatNotEating"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Категории в отметке(не питания)
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              @change="showMarks()"
              checked
            />
            <label class="form-check-label" for="flexCheckChecked">
              Отметки Отсутствия
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              @change="showEatingMark()"
              checked
            />
            <label class="form-check-label" for="flexCheckChecked">
              Показывать "П"
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              @change="showCountEating()"
              checked
            />
            <label class="form-check-label" for="flexCheckChecked">
              Количество порций
            </label>
          </div>
        </div>
        <button
          style="margin-left: 20px"
          target="_blank"
          @click="newPrintStat()"
          class="btn btn-secondary"
        >
          Печать
        </button>
        <button
          style="margin-left: 10px"
          class="btn btn-outline-success"
          @click="pickTable()"
        >
          Скопировать таблицу для вставки в EXCEL
        </button>
      </div>
    </div>
    <div>
      <v-progress-linear
        style="margin: 4px 4px 0 4px"
        v-model="progress"
        v-show="showProgress"
      ></v-progress-linear>

      <div v-if="showLoader">
        <Preloader></Preloader>
      </div>
      <div id="tableSummary" v-if="progress === 100">
        <p>
          Пример формулы для подсчета категорий в таблице: =СЧЁТЕСЛИ(
          <b>*диапазон_значений*</b> ;"П&nbsp;&nbsp;(<b
            >*Название категории (из ACT)*</b
          >)")
        </p>
        <table id="table-data-summary" class="table">
          <tr>
            <th rowspan="2" style="position: sticky; border: 1px">
              <p style="width: 50px">Класс</p>
            </th>
            <th rowspan="2" style="position: sticky">
              <p style="width: 150px">Фамилия Имя</p>
            </th>
            <th rowspan="2" style="position: sticky">
              <p style="width: 150px">Уровень образования</p>
            </th>
            <th rowspan="2" style="width: 150px; position: sticky">
              <p style="width: 80px">Категория (текущая)</p>
            </th>
            <th rowspan="2" style="width: 150px; position: sticky">
              <p style="width: 70px">Смена</p>
            </th>

            <th
              v-for="(month, index) in printTableHead"
              :key="index"
              v-bind:colspan="month.day.length"
            >
              {{ month.month }}
            </th>
          </tr>
          <tr>
            <th
              id="THdays"
              v-for="(day, index) in days"
              :key="index"
              style="writing-mode: bt-rl"
            >
              <p>{{ day }}</p>
            </th>
          </tr>
          <tbody>
            <tr v-for="(stud, index) in tableBodyPrint" :key="index">
              <td
                style="
                  position: sticky;
                  left: 0;
                  z-index: 1;
                  background-color: inherit;
                "
              >
                {{ stud.className }}
              </td>
              <td
                style="
                  width: 400px;
                  position: sticky;
                  left: 52px;
                  z-index: 1;
                  background-color: inherit;
                "
              >
                {{ stud.studentID }}
              </td>
              <td>
                {{ stud.level }}
              </td>
              <td>
                {{ stud.Category }}
              </td>
              <td style="width: 150px">{{ stud.shift }}</td>
              <td
                v-for="(mark, index) in stud.marks"
                :key="index"
                style="font-size: 12px"
                @dblclick="updateMark(mark, stud)"
              >
                <div v-if="mark.mark === 'П'" id="eat">
                  <b id="eatingMark">{{ mark.mark }} &nbsp;</b>
                  <span
                    v-if="
                      mark.cat != '' &&
                      mark.cat != undefined &&
                      mark.cat != '            '
                    "
                    style="font-size: 9px"
                    id="printCat"
                  >
                    ({{ mark.cat }})&nbsp;
                  </span>
                  <span id="countEating">{{ mark.countEating }}</span>
                </div>

                <div
                  v-if="mark.mark !== 'П' && typeof mark.mark == 'string'"
                  id="allMarks"
                >
                  {{ mark.mark }}
                </div>
                <span v-if="showCatNotEating">{{ mark.cat }}</span>
                <div v-if="!stud.studentID && mark.mark > -1">
                  {{ mark.mark }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
      </div>
      <div style="display: flex">
        <ul>
          <li>"Б" - По болезни</li>
          <li>"Бп" - По болезни (Простудные заболевания)</li>
          <li>"З" - Заявление</li>
          <li>"М" - Мероприятие</li>
          <li>"С" - Санаторий</li>
          <li>"Н" - Неуважительно</li>
          <li>"Д" - Домашнее обучение</li>
          <li>"П" - Питался</li>
        </ul>
      </div>
    </div>

    <v-dialog
      v-if="selectedClass.classID === 'admin'"
      v-model="dialogMark"
      width="390px"
    >
      <v-card>
        <v-card-text style="color: black; font-size: 20px">
          Обновление отметки для <br />
          {{ dialogPrint.studentID }}({{ dialogPrint.catStudent }})
        </v-card-text>
        <v-card-actions style="display: flex; flex-wrap: wrap">
          <div>
            <div>
              Дата: {{ new Date(dialogPrint.date).toLocaleDateString() }}
            </div>
            <div>Категория отметки: {{ dialogPrint.catMark }}</div>
            <div>Отметка: {{ dialogPrint.causesID }}</div>
            <div v-if="dialogPrint.countEating">
              Количество порций: {{ dialogPrint.countEating }}
            </div>
            <br />
            Обновить на:
            <div style="display: flex; flex-wrap: nowrap">
              <select
                v-model="dialogUpdate.causes"
                class="form-select"
                aria-label="Default select example"
              >
                <option v-for="(causes, index) in causesList" :key="index">
                  {{ causes.sCauses }}
                </option>
              </select>
              <div v-if="dialogUpdate.causes === 'Питался'">
                <input
                  type="number"
                  id="typeNumber"
                  class="form-control"
                  v-model="dialogUpdate.countEating"
                />
              </div>
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="changeCat"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Изменить категорию отметки на категорию ученика
                (текущую)</label
              >
            </div>
          </div>

          <br />
          <v-btn
            style="margin-top: 10px; margin-bottom: 10px"
            color="success"
            block
            @click="updateMarkServer()"
            >Обновить</v-btn
          >
          <br />
          <v-btn color="primary" block @click="dialogMark = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TutorialDataService from "../services/TutorialDataService";
import Preloader from "./Preloader.vue";
export default {
  components: {
    Preloader: Preloader,
  },
  props: [
    "classList",
    "marksPrint",
    "marks",
    "studentsList",
    "sDates",
    "selectedClassID",
    "selectedClass",
  ],
  data() {
    return {
      temp: [],
      dialogMark: false,
      dialogPrint: {},
      dialogUpdate: { causes: "", markID: "", cat: "", countEating: 1 },
      printTable: [],
      printTableHead: [],
      tableBodyPrint: [],
      days: [],
      progress: 0,
      showProgress: false,
      showLoader: false,
      causesList: [],
      updateMarkSelect: "",
      changeCat: false,
      showCatNotEating: false,
    };
  },
  methods: {
    pickTable() {
      let target = document.getElementById("table-data-summary");
      let rng, sel;
      if (document.createRange) {
        rng = document.createRange();
        rng.selectNode(target);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(rng);
      } else {
        rng = document.body.createTextRange();
        rng.moveToElementText(target);
        rng.select();
      }

      document.execCommand("copy");
    },
    updateMark(mark, stud) {
      if (this.selectedClass.classID === "admin") {
        this.changeCat = false;
        console.log(mark, stud);
        this.updateMarkSelect = { mark: mark, stud: stud };

        this.dialogPrint = {
          studentID: stud.studentID,
          causesID: mark.causesID,
          catStudent: stud.Category,
          catMark: mark.cat,
          date: mark.date,
          countEating: mark.countEating,
        };
        this.dialogMark = true;

        if (this.causesList.length === 0)
          TutorialDataService.getAllCauses()
            .then((response) => {
              this.causesList = response.data.map(this.getDisplayTutorial);
              console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    async updateMarkServer() {
      console.log(this.updateMarkSelect);

      if (this.updateMarkSelect.mark.mark === "n/a") {
        // let datas = [];
        // datas[0] = {
        //   date: this.sDates.date,
        //   classID: data.classID,
        //   studentID: data._id,
        //   causesID: data.mark,
        //   cat: data.Category,
        //   countEating: data.countEating,
        // };
        // await TutorialDataService.createMarks(datas)
        //   .then(async (response) => {
        //     console.log("Создано", response);
        //   })
        //   .catch((e) => {
        //     console.log("1111111111", e);
        //   });
        alert(
          "Пока создать несуществующую отметку отсюда не получится! Перейдите на вкладку 'Главная' и сделайте это там"
        );
      } else {
        var datas = {
          causes: this.dialogUpdate.causes,
          countEating: this.dialogUpdate.countEating,
          cat: this.updateMarkSelect.mark.cat,
        };
        if (this.changeCat) {
          datas.cat = this.updateMarkSelect.stud.Category;
        }
        await TutorialDataService.updateMarkAdmin(
          this.updateMarkSelect.mark.markID,
          datas
        )
          .then((response) => {
            console.log("УСПЕШНО ОТПРАВЛЕНО", response);
            alert(
              "Успешно обновлено, но необходимо повторно сделать загрузку данных, чтобы все другие отчеты правильно работали!"
            );
            this.dialogMark = false;
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      }
    },
    getDisplayTutorial(data) {
      return {
        sCauses: data.causes,
        id: data._id,
      };
    },
    showEat() {
      let el = document.querySelectorAll("#eat");

      for (let i = 0; i < el.length; i++) {
        el[i].hidden = !el[i].hidden;
      }
    },
    showCountEating() {
      let el = document.querySelectorAll("#countEating");

      for (let i = 0; i < el.length; i++) {
        el[i].hidden = !el[i].hidden;
      }
    },
    showEatingMark() {
      let el = document.querySelectorAll("#eatingMark");

      for (let i = 0; i < el.length; i++) {
        el[i].hidden = !el[i].hidden;
      }
    },
    showMarks() {
      let el = document.querySelectorAll("#allMarks");

      for (let i = 0; i < el.length; i++) {
        el[i].hidden = !el[i].hidden;
      }
    },

    showCat() {
      let el = document.querySelectorAll("#printCat");

      for (let i = 0; i < el.length; i++) {
        el[i].hidden = !el[i].hidden;
      }
    },
    progressEdit() {
      if (this.progress < 100) this.progress = this.progress + 10;
      else this.progress = 0;
    },
    showLoaderF() {
      this.showLoader = !this.showLoader;
      this.showProgress = !this.showProgress;
      document.getElementById("btnShowTable").disabled =
        !document.getElementById("btnShowTable").disabled;
    },
    init() {
      this.progress = 0;
      this.showLoaderF();
      this.progress = 10;

      setTimeout(() => {
        this.showTable();
      }, 1000);
      this.progress = 30;
    },
    showTable() {
      this.days = [];
      this.printTable = [];
      let tempSet = new Set();
      //let tempSetDate = new Set();
      let tempArr = [];
      let tempSymbol = "";
      let ArrTempDate = [];
      let marksPrintList = [];
      this.progressEdit();

      let tempCat = new Set();

      for (let i = 0; i < this.marks.length; i++) {
        tempSymbol =
          this.marks[i].date[3] +
          this.marks[i].date[4] +
          this.marks[i].date[2] +
          this.marks[i].date[0] +
          this.marks[i].date[1] +
          this.marks[i].date[5] +
          this.marks[i].date[6] +
          this.marks[i].date[7] +
          this.marks[i].date[8] +
          this.marks[i].date[9];

        tempSet.add(new Date(tempSymbol.replace(/\./g, "/")));
        tempCat.add(this.marks[i].cat);
      }
      this.progressEdit();
      tempArr = Array.from(tempSet);
      tempCat = Array.from(tempCat);

      for (let i = 0; i < tempCat.length; i++) {
        tempCat[i] = { cat: tempCat[i], count: [] };
      }

      for (let i = 0; i < tempArr.length; i++) {
        let tempMonth = tempArr[i].getMonth();
        let tempMonthNmber = tempArr[i].getMonth();
        //  let tempDayWeek = tempArr[i].getDay();
        let tempCausesMark = "";
        if (tempMonth == 0) {
          tempMonth = "Январь";
        }
        if (tempMonth == 1) {
          tempMonth = "Февраль";
        }
        if (tempMonth == 2) {
          tempMonth = "Март";
        }
        if (tempMonth == 3) {
          tempMonth = "Апрель";
        }
        if (tempMonth == 4) {
          tempMonth = "Май";
        }
        if (tempMonth == 5) {
          tempMonth = "Июнь";
        }
        if (tempMonth == 6) {
          tempMonth = "Июль";
        }
        if (tempMonth == 7) {
          tempMonth = "Август";
        }
        if (tempMonth == 8) {
          tempMonth = "Сентябрь";
        }
        if (tempMonth == 9) {
          tempMonth = "Октябрь";
        }
        if (tempMonth == 10) {
          tempMonth = "Ноябрь";
        }
        if (tempMonth == 11) {
          tempMonth = "Декабрь";
        }

        tempCausesMark = this.marks[i].causesID;
        if (this.marks[i].causesID == "По болезни") {
          tempCausesMark = "Б";
        }
        if (this.marks[i].causesID == "По болезни (Простудные заболевания)") {
          tempCausesMark = "Б(п)";
        }
        if (this.marks[i].causesID == "Заявление") {
          tempCausesMark = "Заяв.";
        }
        if (this.marks[i].causesID == "Питался") {
          tempCausesMark = "П";
        }
        if (this.marks[i].causesID == "Мероприятие") {
          tempCausesMark = "М";
        }
        if (this.marks[i].causesID == "Санаторий") {
          tempCausesMark = "С";
        }
        if (this.marks[i].causesID == "Домашнее обучение") {
          tempCausesMark = "Д";
        }
        if (this.marks[i].causesID == "Неуважительно") {
          tempCausesMark = "Н";
        }

        // if (tempDayWeek == 0){
        //   tempDayWeek = "Пн"
        // }
        // if (tempDayWeek == 1){
        //   tempDayWeek = "Вт"
        // }
        // if (tempDayWeek == 2){
        //   tempDayWeek = "Ср"
        // }
        // if (tempDayWeek == 3){
        //   tempDayWeek = "Чт"
        // }
        // if (tempDayWeek == 4){
        //   tempDayWeek = "Пт"
        // }
        // if (tempDayWeek == 5){
        //   tempDayWeek = "Сб"
        // }
        // if (tempDayWeek == 6){
        //   tempDayWeek = "Вс"
        // }

        let tempCountEating = "";
        if (this.marks[i].countEating) {
          tempCountEating = this.marks[i].countEating;
        }
        let tempObj = {
          date: tempArr[i],
          // dayWeek: tempDayWeek,
          month: tempMonth,
          monthNumber: tempMonthNmber,
          mark: tempCausesMark,
          studentID: this.marks[i].studentID,
          causesID: this.marks[i].causesID,
          cat: this.marks[i].cat,
          markID: this.marks[i]._id,
          countEating: tempCountEating,
          level: "выбыл",
          classID: this.marks[i].classID,
        };

        this.printTable.push(tempObj);
      }

      this.progressEdit();
      this.printTable.sort((a, b) => (a.date > b.date ? 1 : -1));
      tempSet.clear();

      for (let i = 0; i < this.printTable.length; i++) {
        tempSet.add(this.printTable[i].month);
      }
      tempArr = Array.from(tempSet);
      tempSet.clear();
      let dateSet = new Set();
      let dateArray = [];
      for (let i = 0; i < tempArr.length; i++) {
        let obj = { month: tempArr[i], date: [], day: [], marks: [] };
        ArrTempDate.push(obj);
        for (let j = 0; j < this.printTable.length; j++) {
          if (this.printTable[j].month === tempArr[i]) {
            dateSet.add(Date.parse(this.printTable[j].date));
            tempSet.add(this.printTable[j].date.getDate());
          }
        }
        ArrTempDate[i].day = Array.from(tempSet);

        tempSet.clear();
      }
      dateArray = Array.from(dateSet);

      for (let i = 0; i < ArrTempDate.length; i++) {
        ArrTempDate[i].day.sort((a, b) => (a > b ? 1 : -1));
      }
      this.printTableHead = ArrTempDate;
      for (let i = 0; i < this.printTableHead.length; i++) {
        for (let j = 0; j < this.printTableHead[i].day.length; j++) {
          this.days.push(this.printTableHead[i].day[j]);
        }
      }
      tempSet.clear();
      for (let i = 0; i < this.printTable.length; i++) {
        tempSet.add(this.printTable[i].studentID);
      }
      tempArr = Array.from(tempSet);

      let tempObj;

      let check = false;
      this.progressEdit();

      for (let o = 0; o < dateArray.length; o++) {
        for (let r = 0; r < tempCat.length; r++) {
          tempCat[r].count.push(0);
        }
      }

      // tempArr - id студентов
      for (let i = 0; i < tempArr.length; i++) {
        this.progressEdit();
        //создать новый объект, что бы его запушить потом
        tempObj = { studentID: tempArr[i], marks: [], month: 0 };
        //проход по числю дней
        for (let o = 0; o < dateArray.length; o++) {
          check = false; // очистка флага проверки заполненности дня
          //проход по всем маркам
          for (let j = 0; j < this.printTable.length; j++) {
            //вход только если студент совпал по марке
            if (tempArr[i] === this.printTable[j].studentID) {
              //если дата совпадает к текущему проходу дня
              if (
                this.printTable[j].date.toDateString() ===
                new Date(dateArray[o]).toDateString()
              ) {
                tempObj.marks.push(this.printTable[j]); //пуш марки в печать
                for (let c = 0; c < tempCat.length; c++) {
                  if (
                    tempCat[c].cat === this.printTable[j].cat &&
                    this.printTable[j].causesID === "Питался"
                  ) {
                    tempCat[c].count[o] += this.printTable[j].countEating;
                  }
                }
                check = true;
                break;
              }
            }
          }
          if (!check) {
            tempObj.marks.push({ causesID: "n/a", mark: "n/a" });
          }
        }
        marksPrintList.push(tempObj);
      }
      this.tableBodyPrint = marksPrintList;

      this.getClassName();
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.tableBodyPrint.length; j++) {
          if (this.tableBodyPrint[j].studentID === this.studentsList[i]._id) {
            this.tableBodyPrint[j].shift = this.studentsList[i].shift;
            this.tableBodyPrint[j].level = this.studentsList[i].level;
            this.tableBodyPrint[j].Category = this.studentsList[i].Category;
            this.tableBodyPrint[j].className = this.studentsList[i].className;
            this.tableBodyPrint[j].studentID =
              this.studentsList[i].FirstName +
              " " +
              this.studentsList[i].LastName;
          }
        }
      }
      for (let i = 0; i < this.tableBodyPrint.length; i++) {
        if (this.tableBodyPrint[i].className === undefined) {
          for (let j = 0; j < this.classList.length; j++) {
            if (
              this.tableBodyPrint[i].marks[0].classID ===
              this.classList[j].classID
            ) {
              this.tableBodyPrint[i].className = this.classList[j].className;
              if (this.classList[j].level === 3) {
                this.tableBodyPrint[i].level = "Среднее";
              }
              if (this.classList[j].level === 2) {
                this.tableBodyPrint[i].level = "Основное";
              }
              if (this.classList[j].level === 1) {
                this.tableBodyPrint[i].level = "Начальное";
              }
              this.tableBodyPrint[i].shift = this.classList[j].shift;
            }
          }
        }
      }
      this.progress = 100;
      this.tableBodyPrint.sort((a, b) => (a.studentID > b.studentID ? 1 : -1));

      this.showLoaderF();

      let tempCatPrintCat = [];
      for (let i = 0; i < tempCat.length; i++) {
        tempCatPrintCat.push({ mark: tempCat[i].cat, marks: [] });
        for (let j = 0; j < tempCat[i].count.length; j++) {
          tempCatPrintCat[i].marks.push({ cat: "", mark: tempCat[i].count[j] });
        }
      }

      for (let i = 0; i < tempCatPrintCat.length; i++) {
        this.tableBodyPrint.push({
          shift: tempCatPrintCat[i].mark,
          marks: tempCatPrintCat[i].marks,
          className: "",
          level: "",
          studentID: "",
        });
      }
    },

    newPrintStat() {
      let auth = localStorage.getItem("user");
      let data = this.sDates.date;
      let printHtml2 = "";

      let top =
        "<head>" +
        "</head><body onload='window.print()'><h2> МБОУ СОШ №24</h2> <h3>Отсутствующие на " +
        data +
        "</h3>";
      let printHtml = document.getElementById("tableSummary").innerHTML; // Получаем содержимое узла для печати

      printHtml2 = document.getElementById("ul-stat").innerHTML;

      let style =
        "<style type='text/css'>.LI-class {color: black;} ul,li {color: black;}table {border: 1px solid black; border-collapse: collapse; font-size:10px}  th,td { padding: 2px;border: 1px solid black; } </style> ";

      let ulstat3 = document.getElementById("ul-stat3").innerHTML;

      printHtml2 += ulstat3;
      printHtml2 += printHtml;
      top += printHtml2;
      top += style;

      top +=
        "<footer><h3>Директор МБОУ СОШ №24 &nbsp &nbsp&nbsp&nbsp&nbsp_________ &nbsp&nbsp&nbsp&nbsp&nbsp&nbspН.А. Голеницкая</h3></footer>";

      if (this.selectedClassID != "") {
        top +=
          "<h3>Классный руководитель &nbsp &nbsp&nbsp&nbsp&nbsp_________ &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
          this.selectedClassID.classLider +
          "</h3>";
      }

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      tab.document.close();
      tab.focus();
      localStorage.setItem("user", auth);

      tab.print();

      tab.onfocus = function () {
        setTimeout(function () {
          tab.close();
        }, 500);
      };
    },

    getClassName() {
      for (let i = 0; i < this.studentsList.length; i++) {
        this.studentsList[i].className = "";
        for (let j = 0; j < this.classList.length; j++) {
          if (this.studentsList[i].classID === this.classList[j].classID) {
            this.studentsList[i].className = this.classList[j].className;
            this.studentsList[i].shift = this.classList[j].shift;
            if (this.classList[j].level === 3) {
              this.studentsList[i].level = "Среднее";
            }
            if (this.classList[j].level === 2) {
              this.studentsList[i].level = "Основное";
            }
            if (this.classList[j].level === 1) {
              this.studentsList[i].level = "Начальное";
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tool {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

ul {
  display: inline-block;
  text-align: left;
  font-size: 12px;
}
TABLE {
  width: 300px; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
}
TD,
TH {
  padding: 2px; /* Поля вокруг содержимого таблицы */
  border: 0.5px solid black; /* Параметры рамки */
}
TH {
  background: #b0e0e6; /* Цвет фона */
  position: sticky;
  top: 0;
  z-index: 220;
}
#THdays {
  top: 28px;
}
tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}

.table {
  cursor: default;
  contain: paint;
}

.table tr {
  position: relative;
}

.table td {
  position: relative;
}

.table td:hover:after {
  pointer-events: none;

  position: absolute;
  z-index: 40;
  top: -5000px;
  left: 0;
  width: 100%;
  height: 10000px;
  content: "";
  background-color: rgba(95, 158, 160, 0.249);
}

tbody tr:hover {
  background: #6c9e54; /* Цвет фона при наведении */
  color: #fff; /* Цвет текста при наведении */
  z-index: 40;
}

.form-check-input {
  margin-top: 7px;
  margin-right: -20px;
  padding: 10px;
}
</style>
